.inventory {
    font-family: Arial, sans-serif;
  }
  
  .inventory-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .inventory-header h1 {
    margin: 0;
  }
  
  .inventory-header button {
    background-color: #ff5252;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .inventory-header button:hover {
    background-color: #e64545;
  }
  
  button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .inventory-list {
    list-style: none;
    padding: 0;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  
  .inventory-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .item-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .item-details {
    margin-bottom: 15px;
    text-align: left;
    width: 100%;
  }
  
  .item-details p {
    margin: 5px 0;
    font-size: 14px;
  }
  
  .inventory-item button {
    margin-top: 10px;
    background-color: #2196f3;
  }
  
  .inventory-item button:hover {
    background-color: #1e88e5;
  }
  
  .inventory-item button:last-child {
    background-color: #ff5252;
  }
  
  .inventory-item button:last-child:hover {
    background-color: #e64545;
  }
  
  .ribbon-circle-top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Hace que el contenedor sea circular */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    z-index: 1;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  
  .ribbon-circle-bottom-left {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: green;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Hace que el contenedor sea circular */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    z-index: 1;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  
  .inventory-item {
    position: relative; /* Necesario para posicionar los círculos */
  }
  