.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .modal-content h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .form-group input:disabled {
    background-color: #f1f1f1;
  }
  
  .form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .form-actions button {
    width: 48%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .form-actions button:first-child {
    background-color: #4caf50;
    color: white;
  }
  
  .form-actions button:first-child:hover {
    background-color: #45a049;
  }
  
  .form-actions button:last-child {
    background-color: #ff5252;
    color: white;
  }
  
  .form-actions button:last-child:hover {
    background-color: #e64545;
  }
  