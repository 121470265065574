.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f9fa;
}

.logout-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout-button:hover .logout-icon {
  color: #dc3545; /* Rojo para indicar acción */
  background-color: none;
}

.logout-icon {
  width: 24px;
  height: 24px;
  color: #333; /* Color del ícono */
  /* transition: color 0.3s ease; */
}
