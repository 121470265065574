.home {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: Arial, sans-serif;
    margin: 0;
  }
  
  .home-header {
    background-color: #f4f4f4;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    flex-shrink: 0;
  }
  
  .home-header h1 {
    margin: 0;
    font-size: 20px;
  }
  
  .home-header button {
    background-color: #ff5252;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .home-header button:hover {
    background-color: #e64545;
  }
  
  .home-body {
    display: flex;
    flex: 1;
  }
  
  .sidebar {
    width: 250px;
    background-color: #222;
    color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex-shrink: 0;
  }
  
  .sidebar h2 {
    margin: 0 0 20px 0;
    font-size: 18px;
  }
  
  .sidebar button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
  }
  
  .sidebar button:hover {
    background-color: #45a049;
  }
  
  .main-content {
    flex-grow: 1;
    background-color: #f9f9f9;
    padding: 20px;
    overflow-y: auto;
  }
  
  .main-content h2 {
    margin-top: 0;
  }
  