.sales-container {
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  color: #333;
}

.sales-list {
  margin-top: 20px;
}

.sales-list h2 {
  color: #555;
}

.sales-list ul {
  list-style: none;
  padding: 0;
}

.sales-list li {
  background-color: #f9f9f9;
  margin: 10px 0;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sales-list li p {
  margin: 5px 0;
}

.add-sale-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.add-sale-button:hover {
  background-color: #0056b3;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.modal-content h2 {
  margin-bottom: 20px;
  color: #333;
}

.modal-content form label {
  display: block;
  margin-bottom: 10px;
  color: #666;
}

.modal-content form input,
.modal-content form select {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.modal-content form input[type="number"] {
  max-width: 100px;
}

.modal-content .product-search {
  margin-bottom: 20px;
}

.modal-content .item-result {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content .item-result img {
  max-width: 100px;
  height: auto;
  margin-bottom: 10px;
  border-radius: 4px;
}

.modal-content .item-result p {
  margin: 5px 0;
  text-align: center;
}

.modal-content .item-result button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content .item-result button:hover {
  background-color: #218838;
}

.modal-content .error-message {
  color: red;
  font-size: 14px;
  text-align: center;
}

.modal-content .close-modal-button {
  margin-top: 10px;
  padding: 10px 20px;
  color: #fff;
  background-color: #dc3545;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content .close-modal-button:hover {
  background-color: #c82333;
}

.sale-products-list {
  margin-top: 20px;
}

.products-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.product-thumbnail {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
}

.product-thumbnail img {
  max-width: 100%;
  max-height: 100%;
}

.product-quantity {
  position: absolute;
  bottom: 5px;
  left: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
}

.remove-product-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-product-button:hover {
  background-color: rgba(255, 0, 0, 1);
}

button {
  cursor: pointer;
}
